@use '@angular/material' as mat;

$typography: mat.define-typography-config();

$wb-default-palette: (
  50: #f2ece8,
  100: #d9cfbb,
  200: #bf9f8d,
  300: #a67960,
  400: #7a5844,
  500: #4d372b,
  600: #3c2b21,
  700: #2b1f18,
  800: #1a1201,
  900: #090605,
  A100: #ff5858,
  A200: #ff2525,
  A400: #f10000,
  A700: #d80000,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: #f2dcb6,
    500: #f2dcb6,
    600: #f2dcb6,
    700: #f2dcb6,
    800: #f2dcb6,
    900: #f2dcb6,
    A100: rgba(black, 0.87),
    A200: rgba(white, 0.7),
    A400: rgba(white, 0.7),
    A700: rgba(white, 0.7),
  ),
);

$default-primary: mat.define-palette($wb-default-palette);
$default-accent: mat.define-palette(mat.$amber-palette);
$default-warn: mat.define-palette(mat.$deep-orange-palette);

$default-theme: mat.define-light-theme(
  (
    color: (
      primary: $default-primary,
      accent: $default-accent,
      warn: $default-warn,
    ),
    typography: $typography,
    density: 0,
  )
);

// Dealer theme
$wb-dealer-palette: (
  50: #e1e8e6,
  100: #b3c5c1,
  200: #819e98,
  300: #4f776f,
  400: #295a50,
  500: #033d31,
  600: #03372c,
  700: #022f25,
  800: #02271f,
  900: #011a13,
  A100: #58ffc3,
  A200: #25ffb1,
  A400: #00f19b,
  A700: #00d88b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$dealer-primary: mat.define-palette($wb-dealer-palette);
$dealer-accent: mat.define-palette(mat.$teal-palette);
$dealer-warn: mat.define-palette(mat.$red-palette);

$dealer-theme: mat.define-light-theme(
  (
    color: (
      primary: $dealer-primary,
      accent: $dealer-accent,
      warn: $dealer-warn,
    ),
    typography: $typography,
    density: 0,
  )
);

// Global variables
$color-positive: #15a224;
$color-negative: mat.get-color-from-palette($default-primary, 'A700');
