@use '@angular/material' as mat;
@import 'src/config';

@include mat.core();

@include mat.all-component-themes($default-theme);

.dealer-theme {
  @include mat.all-component-colors($dealer-theme);
}

@include mat.typography-hierarchy($typography);
