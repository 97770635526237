@use '@angular/material' as mat;
@import 'src/config';

/* You can add global styles to this file, and also import other style files */
a {
  color: mat.get-color-from-palette($default-primary, 600);
  text-decoration: none;
}

body {
  margin: 0;

  div.page-container {
    max-width: 1200px;

    &.centered {
      margin: auto;
    }
  }
}

.text-muted {
  color: mat.get-color-from-palette(map-get($default-theme, foreground), secondary-text);
}

.positive {
  color: $color-positive;
}

.negative {
  color: $color-negative;
}

/*
 * Static Pages styling
 */
div.toc {
  flex: 0 0 auto;

  ul {
    padding-left: 16px;

    li {
      display: block;
      padding-bottom: 8px;

      a {
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}

div.content {
  flex: 0 1 auto;
}
/* ### END ### */

/*
 * Angular Material components styling override
 */
.mat-mdc-snack-bar-container.connection-offline,
.mat-mdc-snack-bar-container.browser-warning {
  background-color: mat.get-color-from-palette($default-warn);
  color: mat.get-color-from-palette($default-warn, '500-contrast');
}

@tailwind base;
@tailwind components;
@tailwind utilities;
